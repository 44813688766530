/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAIplzOhfGRfLm93TI-toO7HfGiZUKqIS4",
  "appId": "1:590102725933:web:d956882d14f0d42bff3fa4",
  "authDomain": "schooldog-i-worth-ga.firebaseapp.com",
  "measurementId": "G-CDJ8KK8S3G",
  "messagingSenderId": "590102725933",
  "project": "schooldog-i-worth-ga",
  "projectId": "schooldog-i-worth-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-worth-ga.appspot.com"
}
